<template>
  <ResponsiveModal
    :open="open"
    @onClose="closePinModal"
    sticky="true"
    :id="id"
    :loading="loading"
  >
    <template v-slot:modal-content>
      <div class="center">
        <div>
          <p class="title text-center">{{ $t("Set New Pin") }}</p>
          <p class="subtitle mb-10 text-center">
         {{ getUserNumber() }}
     </p>
          <div v-if="formError" class="mb-10 form-error text-center">
            {{ formError }}
          </div>
          <div class="center">
            <PinLayout
              id="forgot-pin-pc-1"
              :pinCount="pinCount"
              :value="enteredOtp"
              @onChange="onOtpChange"
              @onCompleted="onOtpEntered"
              :renderError="formError"
            />
          </div>

          <div class="mb-5"></div>

          <p v-if="timeRemaining" class="mb-10 mt-20 subtitle text-center">
            {{ $t("Resend Code in") }}
            <span style="color:red; font-size: 14px;"
              >00:{{ timeRemaining }}</span
            >
          </p>
          <p @click="resendOtp" v-else class="mb-10 mt-20 subtitle text-center">
            {{ $t("Didn't recive OTP?") }}
            <span style="color:red; font-size: 14px;">{{
              $t("Resend")
            }}</span>
          </p>

          <p
            @click="resendOtp"
            v-else
            class="mb-10 form-error text-center pointer"
          >
            {{ $t("Resend") }}
          </p>

          <p class="title mb-10 text-center">{{ $t("Set Pin") }}</p>
          <div v-if="formError2" class="mb-10 form-error text-center">
            {{ formError2 }}
          </div>
          <div class="center">
            <PinLayout
              id="new-pin-pc-1"
              pinCount="4"
              :value="enteredPin"
              @onChange="onPinChange"
              @onCompleted="onPinEntered"
              :renderError="formError2"
            />
          </div>
          <div class="mb-10"></div>

          <div class="mb-10">
            <button
              :disabled="loading"
              @click="onSubmit"
              class="button-primary"
            >
              <DotFlashing v-if="loading" />
              <span v-else>{{ $t("RESET") }}</span>
            </button>
          </div>
        </div>
      </div>
    </template>
  </ResponsiveModal>
</template>

<script>
export default {
  events: ["onClose", "onActionComplete"],
  props: ["open", "pinError", "id", "loading"],
  
  watch: {
    pinError(error, oldError) {
      if (error === "") {
        return;
      }
      this.formError = error;
      setTimeout(() => {
        this.formError = "";
      }, 5000);
    },
    // formError(error, oldError) {
    //   if (error === "") {
    //     return;
    //   }
    //   setTimeout(() => {
    //     this.formError = "";
    //   }, 5000);
    // },
    formError2(error, oldError) {
      if (error === "") {
        return;
      }
      setTimeout(() => {
        this.formError2 = "";
      }, 5000);
    },
    open(newValue, oldValue) {
      if (newValue) {
        this.setupOtpInterval();
      } else {
        this.clearOtpInterval("");
      }
    },
  },

  methods: {
    closePinModal() {
      this.enteredPin = "";
      this.formError = "";
      this.enteredOtp = "";
      this.$emit("onClose");
    },
    getUserNumber () {
      if (!(this.subscriberDetails && this.subscriberDetails.data)) return '';
      let id =  this.subscriberDetails.data && this.subscriberDetails.data.mobileno ? this.hideMobile(this.subscriberDetails.data.mobileno) : this.hideEmail(this.subscriberDetails.data.email);
      let com = this.$t("Please enter the OTP Sent to the Existing Mail/Mobile Number").replace("{{MYID}}", id);
      return com;
    },
    hideEmail(email) {
      const atIndex = email.indexOf("@");
      const username = email.substring(0, atIndex);
      const hiddenUsername = `${username.substring(0, 2)}******${username.substring(atIndex - 2)}`;
      const domain = email.substring(atIndex);
      return `${hiddenUsername}${domain}`;
    },
    hideMobile(mobileNumber) {
      const hidden = `${mobileNumber.substring(0, 2)}******${mobileNumber.substring(mobileNumber.length - 2)}`;
      return `${hidden}`;
    },
    resendOtp() {
      this.setupOtpInterval();
      this.$emit("onResend");
    },

    onPinEntered(pin) {
      this.enteredPin = pin;
    },
    onPinChange(pin) {
      this.enteredPin = pin;
    },
    onOtpEntered(pin) {
      this.enteredOtp = pin;
    },
    onOtpChange(pin) {
      this.enteredOtp = pin;
    },
    onSubmit() {
      if (this.enteredOtp === "" || this.enteredOtp.length < this.pinCount) {
        this.formError = this.$t("Please enter a valid otp");
        return;
      }
      if (this.enteredPin === "" || this.enteredPin.length < 4) {
        this.formError2 = this.$t("Please enter a valid pin");
        return;
      }
      let obj = {
        otp: this.enteredOtp,
        pin: this.enteredPin,
      };
      this.$emit("onActionComplete", obj);
      this.enteredOtp = "";
      this.enteredPin = "";
    },
    clearOtpInterval(timeRemaining) {
      this.timeRemaining = timeRemaining;
      clearInterval(this.otpTimer);
      this.otpTimer = null;
    },
    setupOtpInterval() {
      this.timeRemaining = "30";
      this.otpTimer = setInterval(() => {
        let timeRemaining;
        try {
          timeRemaining = parseInt(this.timeRemaining);
        } catch (NanException) {
          timeRemaining = "";
        }
        if (timeRemaining === "") {
          this.clearOtpInterval(timeRemaining);
          return;
        }

        timeRemaining = timeRemaining - 1;

        let slug = timeRemaining.toString();
        if (slug.length < 2) {
          slug = "0" + slug;
        }
        this.timeRemaining = slug;

        if (timeRemaining <= 0) {
          this.clearOtpInterval("");
        }
      }, 1000);
    },
  },
  mounted () {
    if (localStorage.getItem("subscriberDetails")) {
        this.subscriberDetails = JSON.parse(localStorage.getItem("subscriberDetails"));
      }

      
  },
  components: {
    ResponsiveModal: () => import("@/components/utils/ResponsiveModal.vue"),
    PinLayout: () => import("@/components/utils/PinLayout.vue"),
  },
  data() {
    return {
      enteredPin: "",
      enteredOtp: "",
      pinCount: 6,
      formError: "",
      timeRemaining: "60",
      otpTimer: null,
      formError2: "",
      subscriberDetails: {}
    };
  },
};
</script>

<style lang="scss">
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";
@import "./ForgotPinModal.scss"
</style>
